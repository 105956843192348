import { useRouter } from 'next/router';
import { SVGProps } from 'react';

function AppStore(props: SVGProps<SVGSVGElement>) {
  const { locale } = useRouter();

  function handleText() {
    switch (locale) {
      case 'en':
        return (
          <g className="en">
            <path
              d="M29.2477 6.56974C29.5066 6.55074 29.7667 6.58309 30.0131 6.66493C30.2595 6.74676 30.4874 6.87649 30.6837 7.04667C30.88 7.21686 31.0409 7.42416 31.1572 7.65671C31.2734 7.88927 31.3428 8.14251 31.3612 8.40194C31.3738 8.53266 31.3738 8.66429 31.3612 8.79501C31.3612 10.2215 30.5892 11.0393 29.2604 11.0393H27.6531V6.60144L29.2477 6.56974ZM28.3302 10.4117H29.1718C29.5431 10.4325 29.9075 10.3057 30.186 10.0588C30.4645 9.81183 30.6344 9.46475 30.6588 9.09298C30.6684 8.99601 30.6684 8.89832 30.6588 8.80135C30.7076 8.43099 30.6088 8.05629 30.3837 7.75843C30.1587 7.46057 29.8255 7.26355 29.4565 7.21006C29.3659 7.20373 29.275 7.20373 29.1844 7.21006H28.3428L28.3302 10.4117Z"
              fill="white"
            />
            <path
              d="M32.1332 9.35166C32.1278 8.95068 32.2731 8.56233 32.5403 8.26371C32.8074 7.9651 33.1769 7.77806 33.5753 7.73974C33.9738 7.70142 34.372 7.81461 34.691 8.05685C35.01 8.29909 35.2264 8.65266 35.2972 9.04734C35.3098 9.14839 35.3098 9.25061 35.2972 9.35166C35.3361 9.56873 35.3296 9.79152 35.2784 10.006C35.2271 10.2205 35.132 10.422 34.9992 10.5978C34.8664 10.7737 34.6986 10.9201 34.5066 11.0278C34.3145 11.1355 34.1022 11.2023 33.8831 11.2238C33.6641 11.2453 33.4429 11.2211 33.2336 11.1528C33.0243 11.0845 32.8314 10.9735 32.667 10.8268C32.5027 10.6801 32.3704 10.5009 32.2785 10.3005C32.1866 10.1001 32.1372 9.88281 32.1332 9.66231C32.1205 9.55915 32.1205 9.45481 32.1332 9.35166ZM34.6264 9.35166C34.6264 8.62258 34.2974 8.19147 33.7215 8.19147C33.1457 8.19147 32.8167 8.62258 32.8167 9.35166C32.8167 10.0807 33.1457 10.5118 33.7215 10.5118C34.2974 10.5118 34.6264 10.0871 34.6264 9.35166Z"
              fill="white"
            />
            <path
              d="M39.5307 11.0349H38.8409L38.1449 8.54968H38.0943L37.3982 11.0349H36.7211L35.7909 7.66211H36.4617L37.0944 10.2361H37.1451L37.8348 7.66211H38.4676L39.1637 10.2361H39.2206L39.8154 7.66211H40.4482L39.5307 11.0349Z"
              fill="white"
            />
            <path
              d="M41.2391 7.66341H41.8719V8.2023H41.9225C42.0123 8.00887 42.1614 7.84922 42.3481 7.74659C42.5348 7.64396 42.7492 7.60371 42.9603 7.63171C43.2498 7.61078 43.5357 7.70587 43.7553 7.89608C43.9748 8.0863 44.1099 8.35607 44.131 8.64608C44.1404 8.72611 44.1404 8.80697 44.131 8.887V11.0679H43.4666V9.02013C43.4666 8.48125 43.2324 8.20864 42.7388 8.20864C42.5345 8.19998 42.3349 8.27229 42.1833 8.40992C42.0317 8.54755 41.9403 8.73943 41.9289 8.94405C41.9256 8.98414 41.9256 9.02443 41.9289 9.06451V11.0362H41.2645L41.2391 7.66341Z"
              fill="white"
            />
            <path
              d="M45.1559 6.36523H45.8204V11.0567H45.1559V6.36523Z"
              fill="white"
            />
            <path
              d="M46.757 9.35166C46.7516 8.95068 46.8969 8.56233 47.1641 8.26371C47.4312 7.9651 47.8007 7.77806 48.1991 7.73974C48.5975 7.70142 48.9958 7.81461 49.3147 8.05685C49.6337 8.29909 49.8501 8.65266 49.9209 9.04734C49.9336 9.14839 49.9336 9.25061 49.9209 9.35166C49.9598 9.56873 49.9534 9.79152 49.9021 10.006C49.8508 10.2205 49.7558 10.422 49.623 10.5978C49.4901 10.7737 49.3224 10.9201 49.1303 11.0278C48.9382 11.1355 48.726 11.2023 48.5069 11.2238C48.2878 11.2453 48.0667 11.2211 47.8574 11.1528C47.6481 11.0845 47.4552 10.9735 47.2908 10.8268C47.1264 10.6801 46.9941 10.5009 46.9023 10.3005C46.8104 10.1001 46.7609 9.88281 46.757 9.66231C46.7507 9.55885 46.7507 9.45511 46.757 9.35166ZM49.2502 9.35166C49.2502 8.62258 48.9275 8.19147 48.3516 8.19147C47.7758 8.19147 47.4467 8.62258 47.4467 9.35166C47.4467 10.0807 47.7695 10.5118 48.3516 10.5118C48.9338 10.5118 49.2375 10.0871 49.2375 9.35166H49.2502Z"
              fill="white"
            />
            <path
              d="M50.6234 10.0848C50.6234 9.45084 51.0727 9.1275 51.889 9.07679L52.8002 9.02607V8.73444C52.8002 8.37941 52.5661 8.17653 52.1105 8.17653C51.6549 8.17653 51.4777 8.30967 51.4081 8.55058H50.7753C50.8386 7.96732 51.4081 7.59961 52.1484 7.59961C52.8888 7.59961 53.471 8.01804 53.471 8.73444V11.0358H52.8382V10.5666H52.7812C52.674 10.7362 52.5238 10.8743 52.3459 10.9667C52.1681 11.0592 51.9689 11.1027 51.7688 11.0928C51.6327 11.1105 51.4946 11.1002 51.3626 11.0627C51.2307 11.0252 51.1077 10.9612 51.0012 10.8746C50.8947 10.7881 50.8069 10.6807 50.743 10.5591C50.6791 10.4375 50.6406 10.3042 50.6297 10.1672C50.6255 10.14 50.6234 10.1124 50.6234 10.0848ZM52.7876 9.79319V9.53325L51.9649 9.58397C51.503 9.58397 51.2942 9.77417 51.2942 10.0721C51.2942 10.3701 51.5536 10.554 51.927 10.554C52.1366 10.5753 52.3462 10.5125 52.5098 10.3795C52.6735 10.2464 52.7779 10.0539 52.8002 9.8439L52.7876 9.79319Z"
              fill="white"
            />
            <path
              d="M54.3505 9.35129C54.3505 8.2862 54.8947 7.60784 55.749 7.60784C55.9596 7.59921 56.1683 7.65063 56.3509 7.75612C56.5335 7.86161 56.6824 8.01684 56.7804 8.20378H56.8311V6.36523H57.4955V11.0567H56.8627V10.5242H56.8121C56.7067 10.71 56.5525 10.8632 56.3661 10.9672C56.1798 11.0712 55.9685 11.1219 55.7553 11.1138C54.8631 11.0947 54.3505 10.4227 54.3505 9.35129ZM55.0339 9.35129C55.0339 10.0677 55.3756 10.4988 55.9388 10.4988C56.502 10.4988 56.8437 10.0613 56.8437 9.35129C56.8437 8.64123 56.4957 8.21012 55.9388 8.21012C55.382 8.21012 55.0339 8.64123 55.0339 9.35129Z"
              fill="white"
            />
            <path
              d="M60.2227 9.35166C60.2173 8.95068 60.3626 8.56233 60.6298 8.26371C60.8969 7.9651 61.2664 7.77806 61.6648 7.73974C62.0633 7.70142 62.4615 7.81461 62.7805 8.05685C63.0995 8.29909 63.3158 8.65266 63.3867 9.04734C63.393 9.14868 63.393 9.25032 63.3867 9.35166C63.4255 9.56873 63.4191 9.79152 63.3679 10.006C63.3166 10.2205 63.2215 10.422 63.0887 10.5978C62.9559 10.7737 62.7881 10.9201 62.596 11.0278C62.404 11.1355 62.1917 11.2023 61.9726 11.2238C61.7536 11.2453 61.5324 11.2211 61.3231 11.1528C61.1138 11.0845 60.9209 10.9735 60.7565 10.8268C60.5921 10.6801 60.4599 10.5009 60.368 10.3005C60.2761 10.1001 60.2267 9.88281 60.2227 9.66231C60.21 9.55915 60.21 9.45481 60.2227 9.35166ZM62.7159 9.35166C62.7159 8.62258 62.3869 8.19147 61.811 8.19147C61.2352 8.19147 60.9061 8.62258 60.9061 9.35166C60.9061 10.0807 61.2289 10.5118 61.811 10.5118C62.3932 10.5118 62.7159 10.0871 62.7159 9.35166Z"
              fill="white"
            />
            <path
              d="M64.2916 7.66287H64.9244V8.20175H64.975C65.0518 8.00264 65.1904 7.83353 65.3704 7.71938C65.5505 7.60522 65.7623 7.55207 65.9748 7.56777C66.1187 7.55653 66.2633 7.57389 66.4004 7.61886C66.5376 7.66382 66.6645 7.73549 66.7738 7.82976C66.8832 7.92403 66.9729 8.03903 67.0378 8.16815C67.1027 8.29727 67.1414 8.43797 67.1518 8.58214C67.1581 8.66233 67.1581 8.74287 67.1518 8.82306V11.004H66.4874V9.01959C66.4874 8.48071 66.2469 8.20809 65.7597 8.20809C65.6583 8.20297 65.5568 8.21795 65.4612 8.25216C65.3656 8.28636 65.2776 8.33914 65.2024 8.40745C65.1272 8.47576 65.0661 8.55828 65.0228 8.65026C64.9794 8.74225 64.9546 8.8419 64.9497 8.94351C64.9464 8.9836 64.9464 9.02389 64.9497 9.06397V11.0357H64.2853L64.2916 7.66287Z"
              fill="white"
            />
            <path
              d="M70.9043 6.83008V7.68595H71.6383V8.26922H70.9043V9.98097C70.9043 10.3297 71.0498 10.4882 71.3789 10.4882C71.4653 10.4944 71.552 10.4944 71.6383 10.4882V11.0461C71.5162 11.0557 71.3935 11.0557 71.2713 11.0461C70.5373 11.0461 70.2399 10.7861 70.2399 10.1395V8.26922H69.7083V7.71131H70.2399V6.83008H70.9043Z"
              fill="white"
            />
            <path
              d="M72.5434 6.36523H73.1762V8.22914H73.2268C73.3125 8.02981 73.4576 7.86189 73.6423 7.7484C73.8269 7.6349 74.0421 7.58138 74.2583 7.59516C74.4037 7.58747 74.5493 7.60861 74.6866 7.65737C74.8239 7.70613 74.9502 7.78155 75.0584 7.87932C75.1666 7.97708 75.2544 8.09526 75.3169 8.22709C75.3794 8.35892 75.4153 8.50181 75.4226 8.64757C75.4321 8.71698 75.4321 8.78737 75.4226 8.85678V11.0313H74.7582V9.02796C74.7582 8.48907 74.5051 8.21646 74.0368 8.21646C73.8295 8.1989 73.6238 8.26449 73.4648 8.39882C73.3058 8.53316 73.2066 8.72524 73.1888 8.93286C73.1825 8.97913 73.1825 9.02606 73.1888 9.07234V11.0377H72.5244L72.5434 6.36523Z"
              fill="white"
            />
            <path
              d="M79.3206 10.1296C79.2281 10.4371 79.0317 10.7028 78.7649 10.881C78.4982 11.0592 78.1778 11.1387 77.8588 11.1059C77.6577 11.111 77.4576 11.0763 77.2699 11.0038C77.0821 10.9313 76.9105 10.8225 76.7648 10.6836C76.619 10.5446 76.5021 10.3783 76.4205 10.194C76.339 10.0098 76.2945 9.8112 76.2895 9.60971C76.2832 9.52741 76.2832 9.44475 76.2895 9.36245C76.2412 8.95422 76.3556 8.54336 76.608 8.21925C76.8605 7.89513 77.2304 7.68397 77.6374 7.63168C77.7089 7.62538 77.781 7.62538 77.8525 7.63168C78.7954 7.63168 79.3586 8.26567 79.3586 9.33075V9.53363H76.9793V9.57167C76.9612 9.80258 77.0327 10.0316 77.1788 10.2111C77.325 10.3905 77.5345 10.5067 77.7639 10.5353H77.8525C78.0106 10.5544 78.1708 10.5261 78.3127 10.4538C78.4547 10.3816 78.572 10.2687 78.6498 10.1296H79.3206ZM76.9793 9.03912H78.6815C78.6892 8.9325 78.6759 8.82539 78.6422 8.72396C78.6085 8.62252 78.5551 8.52876 78.4851 8.44805C78.4152 8.36734 78.33 8.30128 78.2344 8.25366C78.1389 8.20604 78.0349 8.1778 77.9285 8.17057H77.8525C77.739 8.16889 77.6262 8.18975 77.5208 8.23193C77.4153 8.27411 77.3192 8.33679 77.238 8.41635C77.1569 8.49591 77.0922 8.59078 77.0478 8.69549C77.0034 8.8002 76.9801 8.91268 76.9793 9.02644V9.03912Z"
              fill="white"
            />
          </g>
        );

      case 'id':
        return (
          <g className="id">
            <path
              d="M28.7117 6.54826C28.9719 6.52924 29.2333 6.56162 29.4809 6.64353C29.7286 6.72543 29.9576 6.85527 30.1549 7.0256C30.3521 7.19592 30.5138 7.4034 30.6307 7.63615C30.7475 7.8689 30.8172 8.12236 30.8357 8.38201C30.8484 8.51284 30.8484 8.64459 30.8357 8.77541C30.8357 10.2031 30.0599 11.0216 28.7244 11.0216H27.1091V6.57998L28.7117 6.54826ZM27.7896 10.3934H28.6354C29.0085 10.4143 29.3748 10.2874 29.6547 10.0402C29.9345 9.79309 30.1053 9.44572 30.1298 9.07364C30.1395 8.97658 30.1395 8.87881 30.1298 8.78176C30.1788 8.41108 30.0795 8.03606 29.8534 7.73795C29.6272 7.43984 29.2924 7.24266 28.9215 7.18912C28.8305 7.18278 28.7391 7.18278 28.6481 7.18912H27.8023L27.7896 10.3934Z"
              fill="white"
            />
            <path
              d="M31.6116 9.33349C31.6061 8.93218 31.7521 8.5435 32.0206 8.24463C32.2891 7.94576 32.6604 7.75857 33.0608 7.72021C33.4612 7.68186 33.8615 7.79515 34.182 8.0376C34.5026 8.28004 34.72 8.63391 34.7912 9.02893C34.8039 9.13005 34.8039 9.23237 34.7912 9.33349C34.8303 9.55075 34.8238 9.77373 34.7723 9.98838C34.7208 10.203 34.6252 10.4047 34.4918 10.5807C34.3583 10.7567 34.1897 10.9033 33.9967 11.0111C33.8036 11.1189 33.5903 11.1857 33.3702 11.2072C33.15 11.2287 32.9277 11.2045 32.7174 11.1362C32.5071 11.0678 32.3132 10.9567 32.148 10.8099C31.9828 10.6631 31.8499 10.4838 31.7575 10.2832C31.6652 10.0826 31.6155 9.8651 31.6116 9.64441C31.5988 9.54116 31.5988 9.43674 31.6116 9.33349ZM34.1171 9.33349C34.1171 8.6038 33.7865 8.17233 33.2078 8.17233C32.6291 8.17233 32.2984 8.6038 32.2984 9.33349C32.2984 10.0632 32.6291 10.4947 33.2078 10.4947C33.7865 10.4947 34.1171 10.0695 34.1171 9.33349Z"
              fill="white"
            />
            <path
              d="M39.0456 11.0221H38.3524L37.6529 8.53481H37.602L36.9025 11.0221H36.2221L35.2872 7.64648H35.9613L36.5973 10.2226H36.6481L37.3413 7.64648H37.9772L38.6768 10.2226H38.734L39.3318 7.64648H39.9677L39.0456 11.0221Z"
              fill="white"
            />
            <path
              d="M40.7627 7.64587H41.3986V8.18521H41.4495C41.5397 7.99162 41.6896 7.83183 41.8772 7.72912C42.0647 7.6264 42.2803 7.58612 42.4924 7.61414C42.7834 7.59319 43.0707 7.68836 43.2913 7.87874C43.5119 8.06911 43.6478 8.33911 43.6689 8.62937C43.6783 8.70947 43.6783 8.79039 43.6689 8.87049V11.0532H43.0012V9.00374C43.0012 8.4644 42.7659 8.19155 42.2699 8.19155C42.0645 8.18289 41.8639 8.25526 41.7116 8.39301C41.5592 8.53075 41.4674 8.72279 41.4559 8.92759C41.4525 8.96771 41.4525 9.00804 41.4559 9.04815V11.0215H40.7881L40.7627 7.64587Z"
              fill="white"
            />
            <path
              d="M44.6991 6.3457H45.3668V11.0411H44.6991V6.3457Z"
              fill="white"
            />
            <path
              d="M46.308 9.33349C46.3026 8.93218 46.4486 8.5435 46.7171 8.24463C46.9855 7.94576 47.3569 7.75857 47.7573 7.72021C48.1577 7.68186 48.5579 7.79515 48.8784 8.0376C49.199 8.28004 49.4165 8.63391 49.4877 9.02893C49.5004 9.13005 49.5004 9.23237 49.4877 9.33349C49.5267 9.55075 49.5203 9.77373 49.4687 9.98838C49.4172 10.203 49.3217 10.4047 49.1882 10.5807C49.0547 10.7567 48.8861 10.9033 48.6931 11.0111C48.5001 11.1189 48.2868 11.1857 48.0666 11.2072C47.8464 11.2287 47.6242 11.2045 47.4138 11.1362C47.2035 11.0678 47.0096 10.9567 46.8444 10.8099C46.6792 10.6631 46.5463 10.4838 46.454 10.2832C46.3617 10.0826 46.312 9.8651 46.308 9.64441C46.3016 9.54087 46.3016 9.43703 46.308 9.33349ZM48.8136 9.33349C48.8136 8.6038 48.4892 8.17233 47.9105 8.17233C47.3318 8.17233 47.0012 8.6038 47.0012 9.33349C47.0012 10.0632 47.3255 10.4947 47.9105 10.4947C48.4956 10.4947 48.8008 10.0695 48.8008 9.33349H48.8136Z"
              fill="white"
            />
            <path
              d="M50.1936 10.0693C50.1936 9.43482 50.6451 9.11122 51.4655 9.06046L52.3812 9.0097V8.71782C52.3812 8.36249 52.1459 8.15944 51.6881 8.15944C51.2302 8.15944 51.0521 8.29269 50.9822 8.53381H50.3462C50.4098 7.95005 50.9822 7.58203 51.7262 7.58203C52.4703 7.58203 53.0553 8.00081 53.0553 8.71782V11.0211H52.4194V10.5516H52.3621C52.2544 10.7213 52.1034 10.8595 51.9247 10.952C51.7459 11.0445 51.5458 11.0881 51.3446 11.0782C51.2079 11.0958 51.0691 11.0856 50.9365 11.048C50.8039 11.0105 50.6803 10.9465 50.5733 10.8598C50.4663 10.7732 50.378 10.6658 50.3138 10.5441C50.2496 10.4224 50.2109 10.2889 50.2 10.1518C50.1958 10.1245 50.1936 10.097 50.1936 10.0693ZM52.3685 9.77746V9.51731L51.5418 9.56807C51.0775 9.56807 50.8677 9.75843 50.8677 10.0567C50.8677 10.3549 51.1284 10.5389 51.5036 10.5389C51.7143 10.5602 51.925 10.4974 52.0894 10.3642C52.2539 10.2311 52.3588 10.0384 52.3812 9.82823L52.3685 9.77746Z"
              fill="white"
            />
            <path
              d="M53.9392 9.33428C53.9392 8.26829 54.4861 7.58936 55.3446 7.58936C55.5563 7.58072 55.766 7.63218 55.9495 7.73776C56.133 7.84334 56.2827 7.99871 56.3812 8.1858H56.4321V6.3457H57.0998V11.0411H56.4639V10.5081H56.413C56.3071 10.6941 56.1521 10.8475 55.9648 10.9516C55.7775 11.0556 55.5652 11.1064 55.351 11.0982C54.4543 11.0792 53.9392 10.4066 53.9392 9.33428ZM54.626 9.33428C54.626 10.0513 54.9694 10.4828 55.5354 10.4828C56.1014 10.4828 56.4448 10.0449 56.4448 9.33428C56.4448 8.62362 56.095 8.19215 55.5354 8.19215C54.9758 8.19215 54.626 8.62362 54.626 9.33428Z"
              fill="white"
            />
            <path
              d="M59.8407 9.33428C59.8407 8.26829 60.3876 7.58936 61.2461 7.58936C61.4576 7.58148 61.6671 7.63326 61.8504 7.73876C62.0338 7.84426 62.1836 7.9992 62.2827 8.1858H62.3336V6.3457H63.0013V11.0411H62.3654V10.5081H62.3145C62.2086 10.6941 62.0536 10.8475 61.8663 10.9516C61.679 11.0556 61.4667 11.1064 61.2525 11.0982C60.3812 11.0792 59.8407 10.4066 59.8407 9.33428ZM60.5275 9.33428C60.5275 10.0513 60.8646 10.4828 61.4305 10.4828C61.9965 10.4828 62.3463 10.0449 62.3463 9.33428C62.3463 8.62362 61.9902 8.19215 61.4305 8.19215C60.8709 8.19215 60.5275 8.62362 60.5275 9.33428Z"
              fill="white"
            />
            <path
              d="M64.0061 6.64942C64.0012 6.56429 64.0217 6.47962 64.0651 6.40612C64.1084 6.33261 64.1726 6.27356 64.2495 6.23642C64.3264 6.19929 64.4127 6.18573 64.4973 6.19746C64.582 6.2092 64.6612 6.24569 64.7251 6.30235C64.789 6.359 64.8346 6.43327 64.8562 6.51577C64.8778 6.59827 64.8744 6.68532 64.8465 6.7659C64.8185 6.84649 64.7673 6.91701 64.6992 6.96855C64.6311 7.0201 64.5492 7.05036 64.4639 7.05551C64.3547 7.05902 64.2484 7.01987 64.1677 6.94641C64.0869 6.87295 64.0381 6.77095 64.0315 6.66211L64.0061 6.64942ZM64.1078 7.64562H64.7501V11.0213H64.1142L64.1078 7.64562Z"
              fill="white"
            />
          </g>
        );
    }
  }

  return (
    <svg
      width={91}
      height={30}
      viewBox="0 0 91 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M83.3386 0.0234375H7.27078H6.58103C6.07848 0.0297225 5.57714 0.0742292 5.08132 0.156574C4.58572 0.239718 4.10564 0.397909 3.65754 0.62572C3.21527 0.860147 2.81185 1.16168 2.46156 1.51963C2.10615 1.87638 1.80929 2.28721 1.58198 2.73688C1.35368 3.18544 1.19573 3.66657 1.11372 4.16334C1.03153 4.65796 0.987111 5.15814 0.980835 5.65953C0.980835 5.89411 0.980835 6.12234 0.980835 6.35057V23.6709C0.980835 23.9055 0.980835 24.1338 0.980835 24.362C0.986839 24.8655 1.03126 25.3678 1.11372 25.8645C1.1967 26.361 1.3546 26.842 1.58198 27.291C1.8094 27.7387 2.10628 28.1474 2.46156 28.5019C2.8166 28.8564 3.22432 29.1537 3.6702 29.3831C4.11918 29.6102 4.59882 29.7703 5.09398 29.8586C5.5898 29.941 6.09114 29.9855 6.59369 29.9917H84.8383C85.3387 29.9855 85.838 29.9409 86.3317 29.8586C86.8291 29.7714 87.311 29.6112 87.7618 29.3831C88.2086 29.1553 88.6166 28.8578 88.9704 28.5019C89.3257 28.146 89.6245 27.7376 89.8563 27.291C90.0789 26.8406 90.2346 26.36 90.3182 25.8645C90.3996 25.3675 90.4462 24.8655 90.4575 24.362V23.6709C90.4575 23.4047 90.4575 23.1321 90.4575 22.8531V7.16841C90.4575 6.8958 90.4575 6.62318 90.4575 6.35057V5.65953C90.4459 5.15816 90.3993 4.65822 90.3182 4.16334C90.2357 3.66754 90.08 3.1868 89.8563 2.73688C89.3903 1.84287 88.6583 1.11617 87.7618 0.657419C87.3118 0.42863 86.8295 0.270405 86.3317 0.188273C85.8388 0.0953019 85.3395 0.0401964 84.8383 0.0234375L84.1485 0.0234375H83.3386Z"
        fill="#A6A6A6"
      />
      <path
        d="M7.27089 29.3483H6.5938C6.12514 29.341 5.65769 29.2986 5.19533 29.2215C4.76559 29.1509 4.34934 29.014 3.96138 28.8158C3.57218 28.6186 3.21744 28.3595 2.91096 28.0487C2.60129 27.7449 2.34461 27.3913 2.1516 27.0026C1.94741 26.6053 1.81055 26.1769 1.74661 25.7346C1.66654 25.2718 1.62422 24.8032 1.62006 24.3335C1.62006 24.175 1.62006 23.6488 1.62006 23.6488V6.3665C1.62006 6.3665 1.62006 5.84664 1.62006 5.69448C1.62526 5.2251 1.66545 4.75676 1.74029 4.29338C1.81143 3.85204 1.95017 3.42434 2.1516 3.02542C2.54503 2.24428 3.17677 1.60914 3.95506 1.21223C4.35323 1.01042 4.78012 0.871423 5.22064 0.800144C5.68525 0.724898 6.15483 0.684633 6.62544 0.679688H7.29619H84.1423H84.8258C85.2922 0.68491 85.7575 0.725175 86.2179 0.800144C86.6578 0.874079 87.0843 1.01295 87.4835 1.21223C88.0664 1.50885 88.5707 1.94002 88.9547 2.47021C89.3386 3.00041 89.5913 3.61443 89.6919 4.26168C89.7557 4.73448 89.7874 5.21105 89.7869 5.68814V6.3665C89.7869 6.65179 89.7869 6.91807 89.7869 7.18434V22.85C89.7869 23.1226 89.7869 23.3889 89.7869 23.6552V24.3526C89.7746 24.8179 89.7302 25.2818 89.654 25.741C89.587 26.1817 89.4526 26.6094 89.2553 27.0089C89.0546 27.3911 88.7988 27.7414 88.496 28.0487C88.1864 28.3542 87.8322 28.6108 87.4455 28.8094C87.0487 29.0145 86.6213 29.1537 86.1799 29.2215C85.7176 29.2986 85.2501 29.341 84.7815 29.3483H7.27089Z"
        fill="black"
      />
      <path
        d="M19.4835 15.2392C19.491 14.6138 19.6562 14.0005 19.9638 13.4562C20.2714 12.912 20.7113 12.4545 21.2427 12.1264C20.9068 11.6465 20.4647 11.2509 19.951 10.9705C19.4373 10.6901 18.8658 10.5324 18.2812 10.5097C17.0157 10.3766 15.807 11.2641 15.1679 11.2641C14.5288 11.2641 13.529 10.5224 12.4659 10.5414C11.7792 10.5653 11.1103 10.7664 10.5239 11.1251C9.9375 11.4839 9.4534 11.9881 9.11844 12.5892C7.67568 15.1251 8.75142 18.7832 10.1372 20.8309C10.8333 21.8263 11.6433 22.9357 12.7 22.8977C13.7568 22.8597 14.1301 22.2637 15.3767 22.2637C16.6233 22.2637 16.984 22.8977 18.0661 22.8977C19.1482 22.8977 19.8822 21.896 20.5466 20.8943C21.047 20.1887 21.4315 19.4075 21.6857 18.5803C21.0283 18.3064 20.4675 17.842 20.0752 17.2468C19.6828 16.6515 19.4768 15.9525 19.4835 15.2392Z"
        fill="white"
      />
      <path
        d="M17.4396 9.17255C18.0479 8.44433 18.3497 7.50765 18.2812 6.56055C17.3497 6.6616 16.4919 7.11513 15.8829 7.82851C15.5797 8.17351 15.3476 8.57517 15.1999 9.01038C15.0522 9.44559 14.9919 9.90577 15.0223 10.3644C15.4894 10.3645 15.9503 10.257 16.3694 10.0504C16.7885 9.84374 17.1547 9.5434 17.4396 9.17255Z"
        fill="white"
      />
      <path
        d="M32.595 20.3647H29.0388L28.1908 22.9006H26.6911L30.0449 13.5938H31.6016L34.9554 22.9006H33.4683L32.595 20.3647ZM29.4311 19.2045H32.2027L30.8232 15.1216H30.7853L29.4311 19.2045Z"
        fill="white"
      />
      <path
        d="M42.2134 19.4904C42.2134 21.5952 41.087 22.952 39.3911 22.952C38.9615 22.9736 38.5344 22.874 38.1585 22.6644C37.7826 22.4548 37.473 22.1437 37.265 21.7664H37.2333V25.1265H35.8412V16.0986H37.189V17.2271C37.4068 16.85 37.7224 16.539 38.1023 16.3269C38.4821 16.1149 38.9122 16.0097 39.3468 16.0225C41.0617 16.0225 42.2134 17.3856 42.2134 19.4904ZM40.7833 19.4904C40.7833 18.1147 40.0746 17.2144 38.9988 17.2144C37.9231 17.2144 37.2207 18.1337 37.2207 19.4904C37.2207 20.8471 37.9357 21.7727 38.9988 21.7727C40.0619 21.7727 40.7833 20.8725 40.7833 19.4904Z"
        fill="white"
      />
      <path
        d="M49.6678 19.4904C49.6678 21.5952 48.5415 22.952 46.8456 22.952C46.4149 22.9749 45.9865 22.8758 45.6093 22.6661C45.2322 22.4565 44.9216 22.1447 44.7131 21.7664H44.6814V25.1265H43.2893V16.0986H44.6371V17.2271C44.855 16.85 45.1705 16.539 45.5504 16.3269C45.9303 16.1149 46.3603 16.0097 46.795 16.0225C48.5351 16.0225 49.6678 17.3856 49.6678 19.4904ZM48.2377 19.4904C48.2377 18.1147 47.529 17.2144 46.4533 17.2144C45.3775 17.2144 44.6751 18.1337 44.6751 19.4904C44.6751 20.8471 45.3902 21.7727 46.4533 21.7727C47.5164 21.7727 48.2377 20.8725 48.2377 19.4904Z"
        fill="white"
      />
      <path
        d="M54.5972 20.3157C54.6985 21.235 55.5907 21.8436 56.8183 21.8436C58.0459 21.8436 58.8306 21.2096 58.8306 20.4044C58.8306 19.6817 58.3243 19.2506 57.1157 18.9526L55.9134 18.661C54.2112 18.2489 53.4202 17.4501 53.4202 16.1251C53.4202 14.5148 54.8123 13.418 56.7993 13.418C58.7863 13.418 60.1025 14.5148 60.1531 16.1251H58.7799C58.6977 15.1931 57.9257 14.6352 56.812 14.6352C55.6983 14.6352 54.9136 15.1995 54.9136 16.03C54.9136 16.664 55.4072 17.0697 56.6031 17.3677L57.6283 17.6213C59.5266 18.0714 60.324 18.8385 60.324 20.2016C60.324 21.9387 58.9381 23.0291 56.736 23.0291C54.6795 23.0291 53.2873 21.964 53.1987 20.284L54.5972 20.3157Z"
        fill="white"
      />
      <path
        d="M63.2979 14.4883V16.0986H64.5635V17.2017H63.2979V20.9486C63.2979 21.5255 63.5574 21.7981 64.1206 21.7981C64.2744 21.8077 64.4287 21.8077 64.5825 21.7981V22.8505C64.3279 22.8968 64.0693 22.918 63.8105 22.9139C62.4373 22.9139 61.9121 22.4004 61.9121 21.0817V17.2017H60.9313V16.0986H61.9121V14.4883H63.2979Z"
        fill="white"
      />
      <path
        d="M65.3358 19.4879C65.3358 17.3514 66.6013 16.0137 68.544 16.0137C70.4867 16.0137 71.7586 17.3514 71.7586 19.4879C71.7586 21.6244 70.493 22.9621 68.544 22.9621C66.595 22.9621 65.3358 21.6307 65.3358 19.4879ZM70.3411 19.4879C70.3411 18.0234 69.6704 17.1612 68.544 17.1612C67.4176 17.1612 66.7469 18.0297 66.7469 19.4879C66.7469 20.946 67.4176 21.8146 68.544 21.8146C69.6704 21.8146 70.3411 20.9587 70.3411 19.4879Z"
        fill="white"
      />
      <path
        d="M72.9037 16.0978H74.2263V17.2517H74.2642C74.3529 16.8955 74.5595 16.5799 74.8503 16.3565C75.1411 16.1332 75.4988 16.0151 75.8652 16.0217C76.0251 16.0216 76.1844 16.0407 76.3398 16.0788V17.3785C76.135 17.3154 75.921 17.2876 75.707 17.2961C75.3354 17.2806 74.9728 17.4134 74.6988 17.6653C74.4248 17.9173 74.2617 18.2678 74.2452 18.6401C74.2389 18.7118 74.2389 18.7839 74.2452 18.8556V22.8814H72.8531L72.9037 16.0978Z"
        fill="white"
      />
      <path
        d="M82.7755 20.889C82.592 22.1189 81.3896 22.9621 79.8583 22.9621C77.8903 22.9621 76.6943 21.6434 76.6943 19.5196C76.6943 17.3958 77.922 16.0137 79.8583 16.0137C81.7946 16.0137 82.9083 17.2816 82.9083 19.3548V19.8366H78.1245V19.919C78.0876 20.3834 78.235 20.8435 78.5347 21.1996C78.8345 21.5557 79.2623 21.779 79.7254 21.8209C79.7991 21.8272 79.8732 21.8272 79.9469 21.8209C80.2749 21.8519 80.604 21.7762 80.8856 21.6049C81.1672 21.4335 81.3861 21.1758 81.5099 20.87L82.7755 20.889ZM78.0738 18.8602H81.4656C81.488 18.4284 81.3389 18.0052 81.0507 17.6832C80.7625 17.3613 80.3588 17.1667 79.9279 17.1422C79.886 17.1358 79.8433 17.1358 79.8013 17.1422C79.5761 17.1405 79.3528 17.1833 79.1441 17.2681C78.9354 17.3529 78.7454 17.478 78.585 17.6364C78.4246 17.7948 78.2969 17.9832 78.2092 18.191C78.1215 18.3989 78.0755 18.6219 78.0738 18.8476V18.8602Z"
        fill="white"
      />
      {handleText()}
    </svg>
  );
}

export default AppStore;
